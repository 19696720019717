
export default {
  name: "BackgroundVideoPlayer",
  data: () => ({
    playerOptions: {},
    sources: [],
  }),
  mounted() {
    this.playerOptions = {
      height: "360",
      muted: true,
      autoplay: true,
      controls: false,
      loop: true,
      language: "en",
      errorDisplay: false,
      poster: require("~/static/poster-0c05b80ca99c2934049c00a563b9b104e8767c88f27a92d6558265fd4b0f4960.jpg"),
    }
    this.sources = [
      {
        src: `${this.$config.public.evercamWebsiteUrl}/gpoview.webm`,
        type: "video/webm",
      },
      {
        src: `${this.$config.public.evercamWebsiteUrl}/gpoview.mp4`,
        type: "video/mp4",
      },
      {
        src: `${this.$config.public.evercamWebsiteUrl}/gpoview.ogv`,
        type: "video/ogg",
      },
    ]
  },
}
